import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startVerifyAuth } from '../actions/auth/auth';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from '../pages/login/Login';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import Loader from '../components/loader/Loader';
import DashBoardRoutes from './DashBoardRoutes';
import { styles } from '../helpers/muiTheme';

const defaultTheme = createTheme(styles);

const AppRouter = () => {

    const dispatch = useDispatch();

    const { loaded } = useSelector(state => state.auth);

    useEffect(() => {
        dispatch(startVerifyAuth());
    }, [dispatch]);

    if (!loaded) return (
        <Loader containStyles={{ with: '100%', height: '100vh' }} text='Cargando' />
    );

    return (
        <ThemeProvider theme={defaultTheme}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <PublicRoutes>
                                <Login />
                            </PublicRoutes>
                        }
                    />
                    <Route
                        path='/*'
                        element={
                            <PrivateRoutes>
                                <DashBoardRoutes />
                            </PrivateRoutes>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default AppRouter;