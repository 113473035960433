import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { warningAlert } from '../../helpers/alerts';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Modal, Typography } from '@mui/material';
import { startAddImage } from '../../actions/products/products';

import './modals.css';

const ImagesModal = ({ open, setOpen }) => {

    const dispatch = useDispatch();

    const { activeProduct } = useSelector(state => state.products);

    const [image, setImage] = useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = { product_id: activeProduct?.id, image };

        warningAlert({
            text: '¿Quieres agregar esta imagen?',
            confirmButtonText: 'Guardar',
            onConfirm: () => {
                dispatch(startAddImage({ data }));
                handleClose();
            }
        });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Box
                className='modal_form'
                component='form'
                sx={{ backgroundColor: 'var(--mainWhite)', borderRadius: '8px' }}
                onSubmit={handleSubmit}
            >
                <Typography variant='h4' fontSize='1.2rem' color='primary'>
                    Agregar imagen
                </Typography>
                <Box
                    display='flex'
                    flexWrap='wrap'
                    alignItems='center'
                    gap='0 .5rem'
                    marginTop={2}
                >
                    <input
                        type='file'
                        accept='image/webp'
                        required
                        onChange={(e) => setImage(e.target.files[0])}
                    />
                    <InfoIcon
                        sx={{ fontSize: '1rem', cursor: 'pointer', color: 'var(--mainOrange)' }}
                        data-tooltip-id='image-info'
                        data-tooltip-content='Max. 2MB / Webp. / Recomendado. 800 x 800px'
                        data-tooltip-place='top'
                    />
                    <Tooltip id='image-info' />
                </Box>

                <Button
                    variant="contained"
                    fullWidth
                    type='submit'
                    sx={{ margin: '2rem 0 8px 0' }}
                >
                    Guardar
                </Button>
            </Box>
        </Modal>
    );
};

ImagesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default ImagesModal;