import { fetchApi } from "../../api/fetch";
import { types } from "../../types/types";

export const startGettingCategories = () => {
    return async (dispatch) => {
        try {
            const { data: { data, status } } = await fetchApi({ url: '/categories' });
            
            if(status) {
                dispatch(categoriesLoaded({ categories: data }));
            } else {
                dispatch(categoriesLoaded({ categories: [] }));
            }   
        } catch (error) {
            console.log(error);
        }
    };
};

const categoriesLoaded = ({ categories }) => ({
    type: types.categoriesLoaded,
    payload: categories
});

export const setActiveCategory = ({ category }) => ({
    type: types.categoriesSetActive,
    payload: category
});