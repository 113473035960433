import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startChangeProductState, startDeleteProduct, startGetProduct } from '../../actions/products/products';
import Title from '../../components/title/Title';
import MainInfo from '../../components/mainInfo/MainInfo';
import VersionsInfo from '../../components/versionsInfo/VersionsInfo';
import Images from '../../components/images/Images';
import Loader from '../../components/loader/Loader';
import { warningAlert } from '../../helpers/alerts';
import { Box, Button, IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import VersionsModal from '../../components/modals/VersionsModal';
import ImagesModal from '../../components/modals/ImagesModal';
import ViewsInfo from '../../components/views/ViewsInfo';
import ViewsModal from '../../components/modals/ViewsModal';
import Viewer360 from '../../components/modals/Viewer360';
import NotFound from '../../components/not-found/NotFound';

const Product = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { product, category: paramsCategory } = useParams();

    const { loadingProduct } = useSelector(state => state.ui);

    const { activeProduct } = useSelector(state => state.products);
    const {
        name,
        category,
        brand,
        price,
        discount_price,
        main_features,
        features,
        images,
        colors,
        versions,
        active,
        outlined
    } = activeProduct ?? {};

    const [versionsModal, setVersionsModal] = useState({ open: false, version: null });
    const [imagesModal, setImagesModal] = useState(false);
    const [viewsModal, setViewsModal] = useState(false);
    const [viewer360Modal, setViewer360Modal] = useState({ open: false, color: null });

    const handleProductState = (state) => {
        warningAlert({
            text: '¿Quieres cambiar el estado de este producto?',
            confirmButtonText: 'Cambiar',
            onConfirm: () => dispatch(startChangeProductState({ product, state }))
        });
    };

    const handleDelete = () => {
        warningAlert({
            text: '¿Quieres eliminar este producto?',
            confirmButtonText: 'Eliminar',
            onConfirm: () => dispatch(startDeleteProduct({ product, category: paramsCategory, navigate }))
        });
    };

    useEffect(() => {
        dispatch(startGetProduct({ product }));
    }, [dispatch, product]);

    if (!activeProduct && !loadingProduct) {
        return <NotFound />
    }

    return (
        <>
            {
                loadingProduct
                    ? <Loader />
                    :
                    <>
                        <Title
                            text={`${name} ${active ? '(Publicado)' : '(Sin publicar)'}`}
                            btn={false}
                        />

                        <Box display='flex' gap='0 1rem'>
                            {
                                active
                                    ?
                                    <Button
                                        onClick={() => handleProductState('inactivate')}
                                        variant='contained'
                                        color='error'
                                    >
                                        Inactivar
                                    </Button>
                                    :
                                    <Button
                                        onClick={() => handleProductState('activate')}
                                        variant='contained'
                                        color='success'
                                    >
                                        Publicar
                                    </Button>
                            }

                            {
                                outlined
                                    ?
                                    <Button
                                        onClick={() => handleProductState('flat')}
                                        variant='contained'
                                        endIcon={<StarBorderIcon />}
                                        sx={{ background: '#F3712B' }}
                                    >
                                        Quitar destacado
                                    </Button>
                                    :
                                    <Button
                                        onClick={() => handleProductState('outline')}
                                        variant='contained'
                                        endIcon={<StarIcon />}
                                        sx={{ background: '#E8E100' }}
                                    >
                                        Destacar
                                    </Button>
                            }

                            <Button
                                variant='contained'
                                color='error'
                                onClick={handleDelete}
                            >
                                Eliminar
                            </Button>
                        </Box>

                        <MainInfo
                            category={category}
                            brand={brand}
                            price={price}
                            discount_price={discount_price}
                            colors={colors}
                            main_features={main_features}
                            features={features}
                        />

                        <VersionsInfo
                            versions={versions}
                            openModal={setVersionsModal}
                        />

                        <Images
                            images={images}
                            openModal={() => setImagesModal(true)}
                        />

                        <ViewsInfo
                            openModal={() => setViewsModal(true)}
                            openViewer={setViewer360Modal}
                            colors={colors}
                        />

                        <VersionsModal openData={versionsModal} setOpen={setVersionsModal} />
                        <ImagesModal open={imagesModal} setOpen={setImagesModal} />
                        <ViewsModal open={viewsModal} setOpen={setViewsModal} />
                        <Viewer360 openData={viewer360Modal} setOpen={setViewer360Modal} />
                    </>
            }
        </>
    );
};

export default Product;