export const featuresTemplate = `
    <h3>Sistema Operativo</h3>
    <br/>
    <ul>
        <li></li>
    </ul>
    <br/>
    <h3>Dimensiones</h3>
    <br/>
    <ul>
        <li></li>
    </ul>
    <br/>
    <h3>Pantalla</h3>
    <br/>
    <ul>
        <li></li>
    </ul>
    <br/>
    <h3>Procesador</h3>
    <br/>
    <ul>
        <li></li>
    </ul>
    <br/>
    <h3>Memoria</h3>
    <br/>
    <ul>
        <li></li>
    </ul>
    <br/>
    <h3>Cámara</h3>
    <br/>
    <ul>
        <li></li>
    </ul>
    <br/>
    <h3>Conectividad</h3>
    <br/>
    <ul>
        <li></li>
    </ul>
    <br/>
    <h3>Sensores</h3>
    <br/>
    <ul>
        <li></li>
    </ul>
    <br/>
    <h3>Batería</h3>
    <br/>
    <ul>
        <li></li>
    </ul>
    <br/>
`;