import { fetchApi } from "../../api/fetch";
import { types } from "../../types/types";
import { uiFinishLogin, uiStartLogin } from "../ui/ui";

export const startLogin = ({ email, password }) => {
    return async (dispatch) => {
        try {
            const body = new FormData();
            body.append('email', email);
            body.append('password', password);

            dispatch(uiStartLogin());

            const { data: { data, token, token_type, msg, status } } = await fetchApi({
                method: 'post',
                url: 'login',
                data: body
            });

            if (!status) {
                dispatch(logError({ msg }));
            } else {
                dispatch(authLoggedIn({ data }));

                localStorage.setItem('access-token', token);
                localStorage.setItem('token-type', token_type);
            }

            dispatch(uiFinishLogin());
        } catch (error) {
            console.log(error);
        }
    };
};

export const startVerifyAuth = () => {
    return async (dispatch) => {
        try {
            const { data: { data, token, token_type, status } } = await fetchApi({ 
                url: '/verify-authentication', 
                withToken: true 
            });
            
            if (!status) {
                dispatch(authLoggedOut());

                localStorage.removeItem('access-token');
                localStorage.removeItem('token-type');
            } else {
                dispatch(authLoggedIn({ data }));

                localStorage.setItem('access-token', token);
                localStorage.setItem('token-type', token_type);
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const authLoggedIn = ({ data }) => ({
    type: types.authLoggedIn,
    payload: data
});

const logError = ({ msg }) => ({
    type: types.authLogErrors,
    payload: msg
});

export const startLogOut = () => {
    return async (dispatch) => {
        try {
            const { data: { msg, status } } = await fetchApi({ 
                url: '/logout', 
                withToken: true 
            });
            
            if(!status) {
                console.log(msg);
            } else {
                dispatch(authLoggedOut());

                localStorage.removeItem('access-token');
                localStorage.removeItem('token-type');
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const authLoggedOut = () => ({
    type: types.authLoggedOut
});