import { types } from "../types/types";

const initState = {
    products: [],
    total: 0,
    activeProduct: null,
    view360: []
};

export const productsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.productsLoaded:
            return {
                ...state,
                products: action.payload.products,
                total: action.payload.total
            };

        case types.productsSetActive:
            return {
                ...state,
                activeProduct: action.payload
            };

        case types.productUpdated:
            return {
                ...state,
                activeProduct: { ...state.activeProduct, ...action.payload }
            };

        case types.productAddVersion:
            return {
                ...state,
                activeProduct: {
                    ...state.activeProduct,
                    versions: [
                        ...state.activeProduct.versions,
                        action.payload
                    ]
                }
            };

        case types.productUpdateVersion:
            return {
                ...state,
                activeProduct: {
                    ...state.activeProduct,
                    versions: state.activeProduct.versions.map(
                        version => version.id === action.payload.id ? action.payload : version
                    )
                }
            };

        case types.productAddImage:
            return {
                ...state,
                activeProduct: {
                    ...state.activeProduct,
                    images: [
                        ...state.activeProduct.images,
                        action.payload
                    ]
                }
            };

        case types.productUpdateImage:
            return {
                ...state,
                activeProduct: {
                    ...state.activeProduct,
                    images: state.activeProduct.images.map(
                        image => image.id === action.payload.id ? action.payload : image
                    )
                }
            };

        case types.productDeleteImage:
            return {
                ...state,
                activeProduct: {
                    ...state.activeProduct,
                    images: state.activeProduct.images.filter(
                        image => image.id !== action.payload.id
                    )
                }
            };

        case types.productViewLoaded:
            return {
                ...state,
                view360: action.payload
            };

        default:
            return state;
    }
};