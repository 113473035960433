import { types } from "../../types/types";
import { fetchApi } from "../../api/fetch";
import { errorAlert, loadingAlert, succesAlert } from "../../helpers/alerts";
import { uiProductLoaded, uiProductLoading, uiProductsLoaded, uiProductsLoading, uiViewLoaded, uiViewLoading } from "../ui/ui";

export const startGettingProducts = ({ page, category = 'smartphones', brand = 'all', order = null }) => {
    return async (dispatch) => {
        try {
            dispatch(uiProductsLoading());

            const { data: { data, status } } =
                await fetchApi({ url: `/products/category/${category}/brand/${brand}/all?page=${page}` });

            const { data: products, total } = data;

            if (status) {
                dispatch(productsLoaded({ products, total }));
            }

            dispatch(uiProductsLoaded());
        } catch (error) {
            console.log(error);
        }
    };
};

const productsLoaded = ({ products, total }) => ({
    type: types.productsLoaded,
    payload: { products, total }
});

export const startAddProduct = ({ data, colors, image, category, navigate }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Registrando producto...' });

            const body = new FormData();

            for (let key in data) {
                body.append(key, data[key]);
            }

            colors.forEach(color => {
                body.append('colors[]', color);
            });

            body.append('image', image);

            const { data: { msg, status } } =
                await fetchApi({ url: '/products', method: 'post', data: body, withToken: true });

            if (status) {
                succesAlert({
                    text: msg,
                    showConfirmButton: true,
                    onConfirm: () => navigate(`/productos/${category}`)
                });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {

        };
    };
};

export const startUpdateProduct = ({ data, colors, category, navigate, product }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Guardando cambios...' });

            const body = new FormData();

            for (let key in data) {
                body.append(key, data[key]);
            }

            colors.forEach(color => {
                body.append('colors[]', color);
            });

            const { data: { msg, status } } =
                await fetchApi({ 
                    url: `/products/${product}?_method=PUT`, 
                    method: 'post', 
                    data: body, 
                    withToken: true
                });

            if (status) {
                succesAlert({
                    text: msg,
                    showConfirmButton: true,
                    onConfirm: () => navigate(`/productos/${category}/${data.slug}`)
                });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const startGetProduct = ({ product }) => {
    return async (dispatch) => {
        try {
            dispatch(uiProductLoading());

            const { data: { data, status } } = await fetchApi({ url: `/products/${product}` });

            if (status) {
                dispatch(productsSetActive({ product: data }));
            } else {
                dispatch(productsSetActive({ product: null }));
            }

            dispatch(uiProductLoaded());

        } catch (error) {
            console.log(error);
        }
    };
};

const productsSetActive = ({ product }) => ({
    type: types.productsSetActive,
    payload: product
});

export const startAddVersion = ({ data, stocks }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Registrando versión...' });

            const body = new FormData();

            for (let key in data) {
                body.append(key, data[key]);
            }

            // * Setear precio de descuento en caso de llegar vacio o ser 0
            const stocksToSave = stocks.map(item => (
                item.discount_price === '0' || item.discount_price === ''
                ? { ...item, discount_price: null }
                : item
            ));

            stocksToSave.forEach(stock => {
                body.append('stocks[]', JSON.stringify(stock));
            });

            const { data: { data: version, status, msg } } = await fetchApi({
                url: '/versions',
                method: 'post',
                data: body,
                withToken: true
            });

            if (status) {
                const newStocks = version.stocks.map(item => ({
                    version_id: version.id,
                    id: item.id,
                    name: item.name,
                    stock: stocks.find(e => e.state_id === item.id).stock,
                    price: stocks.find(e => e.state_id === item.id).price,
                    discount_price: stocks.find(e => e.state_id === item.id).discount_price ?? '',
                }));

                const newVersion = {
                    id: version.id,
                    product_id: version.product_id,
                    name: version.name,
                    stocks: newStocks
                };

                dispatch(productAddVersion({ version: newVersion }));

                succesAlert({
                    text: msg,
                    showConfirmButton: true,
                });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const productAddVersion = ({ version }) => ({
    type: types.productAddVersion,
    payload: version
});

export const startUpdateVersion = ({ name, stocks, version_id }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Guardando cambios...' });

            const body = new FormData();

            body.append('name', name);

            // * Setear precio de descuento en caso de llegar vacio o ser 0
            const stocksToSave = stocks.map(item => (
                item.discount_price === '0' || item.discount_price === ''
                ? { ...item, discount_price: null }
                : item
            ));

            stocksToSave.forEach(stock => {
                body.append('stocks[]', JSON.stringify(stock));
            });


            const { data: { data: version, status, msg } } = await fetchApi({
                url: `/versions/${version_id}?_method=PUT`,
                method: 'post',
                data: body,
                withToken: true
            });

            const newStocks = version.stocks.map(item => ({
                version_id: version.id,
                id: item.id,
                name: item.name,
                stock: stocks.find(e => e.state_id === item.id).stock,
                price: stocks.find(e => e.state_id === item.id).price,
                discount_price: stocks.find(e => e.state_id === item.id).discount_price ?? '',
            }));

            const newVersion = {
                id: version.id,
                product_id: version.product_id,
                name: version.name,
                stocks: newStocks
            };

            if (status) {
                dispatch(productUpdateVersion({ version: newVersion }));

                succesAlert({
                    text: msg,
                    showConfirmButton: true,
                });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const productUpdateVersion = ({ version }) => ({
    type: types.productUpdateVersion,
    payload: version
});

export const startAddImage = ({ data }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Guardando imagen...' });

            const body = new FormData();

            for (let key in data) {
                body.append(key, data[key]);
            }

            const { data: { data: image, msg, status } } = await fetchApi({
                url: '/images',
                method: 'post',
                data: body,
                withToken: true
            });

            if (status) {
                dispatch(productAddImage({ image }));
                succesAlert({
                    text: msg,
                    showConfirmButton: true,
                });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const productAddImage = ({ image }) => ({
    type: types.productAddImage,
    payload: image
});

export const startUpdateImage = ({ image_id, is_main }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Guardando cambios...' });

            const body = new FormData();
            body.append('is_main', is_main);

            const { data: { data, msg, status } } = await fetchApi({
                url: `/images/${image_id}?_method=PUT`,
                method: 'post',
                data: body,
                withToken: true
            });

            if(status) {
                dispatch(productUpdateImage({ image: data }));
                succesAlert({ text: msg, showConfirmButton: true });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const productUpdateImage = ({ image }) => ({
    type: types.productUpdateImage,
    payload: image
});

export const startDeleteImage = ({ image_id }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Eliminando...' });

            const { data: { msg, status } } = await fetchApi({
                url: `/images/${image_id}`,
                method: 'delete',
                withToken: true
            });

            if(status) {
                dispatch(productDeleteImage({ id: image_id }));
                succesAlert({ text: msg, showConfirmButton: true });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const productDeleteImage = ({ id }) => ({
    type: types.productDeleteImage,
    payload: { id }
});

export const startAddView = ({ data, images }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Creando vista 360...' });

            const body = new FormData();

            for (let key in data) {
                body.append(key, data[key]);
            }

            Array.from(images).forEach(image => {
                body.append('images[]', image);
            });

            const { data: { msg, status } } = await fetchApi({
                url: '/views',
                method: 'post',
                data: body,
                withToken: true
            });

            if (status) {
                succesAlert({
                    text: msg,
                    showConfirmButton: true,
                });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const startGettingView = ({ product_id, color_id }) => {
    return async (dispatch) => {
        try {
            dispatch(uiViewLoading());

            const { data: { data, status } } = await fetchApi({ url: `/views/${product_id}/${color_id}` });

            if(status) {
                dispatch(productViewLoaded({ views: data }));
            } else {
                dispatch(productViewLoaded({ views: [] }));
            }

            dispatch(uiViewLoaded());
        } catch (error) {
            console.log(error);
        }
    };
};

const productViewLoaded = ({ views }) => ({
    type: types.productViewLoaded,
    payload: views
});

export const startDeleteView = ({ product_id, color_id }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Eliminando...' });

            const { data: { status, msg } } = await fetchApi({
                url: `/views/${product_id}/${color_id}`,
                method: 'delete',
                withToken: true
            });

            if(status) {
                succesAlert({ text: msg, showConfirmButton: true });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const startChangeProductState = ({ product, state }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Guardando cambios...' });

            const { data: { status, msg } } = await fetchApi({
                url: `/products/${product}/${state}?_method=PUT`,
                method: 'post',
                withToken: true
            });

            if(status) {
                const fields = {
                    activate: { field: 'active', value: 1 },
                    inactivate: { field: 'active', value: 0 },
                    outline: { field: 'outlined', value: 1 },
                    flat: { field: 'outlined', value: 0 }
                };

                const field = fields[state].field;
                const value = fields[state].value;


                dispatch(productUpdated({ data: { [field]: value } }));
                succesAlert({ text: msg, showConfirmButton: true });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const productUpdated = ({ data }) => ({
    type: types.productUpdated,
    payload: data
});

export const startDeleteProduct = ({ product, category, navigate }) => {
    return async (dispatch) => {
        try {
            loadingAlert({ text: 'Eliminando...' });
            
            const { data: { msg, status } } = await fetchApi({
                url: `/products/${product}`,
                method: 'delete',
                withToken: true
            });

            if(status) {
                succesAlert({
                    text: msg,
                    showConfirmButton: true,
                    onConfirm: () => navigate(`/productos/${category}`)
                });
            } else {
                errorAlert({ text: msg });
            }
        } catch (error) {
            console.log(error);
        }
    };
};