import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { uiReducer } from './uiReducer';
import { productsReducer } from './productsReducer';
import { categoriesReducer } from './categoriesReducer';
import { brandsReducer } from './brandsReducer';
import { colorsReducer } from './colorsReducer';
import { statesReducer } from './statesReducer';

export const rootReducer = combineReducers({
    ui: uiReducer,
    auth: authReducer,
    states: statesReducer,
    categories: categoriesReducer,
    brands: brandsReducer,
    colors: colorsReducer,
    products: productsReducer
});