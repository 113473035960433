export const productsColumns = [
    {
        id: 'images',
        type: 'image',
        sub: 'url',
        name: 'Imagen'
    },
    {
        id: 'name',
        type: 'text',
        name: 'Nombre'
    },
    {
        id: 'price',
        type: 'number',
        name: 'Precio'
    },
    {
        id: 'discount_price',
        type: 'number',
        name: 'Descuento'
    },
    {
        id: 'category',
        type: 'text',
        sub: 'name',
        name: 'Categoria'
    },
    {
        id: 'brand',
        type: 'text',
        sub: 'name',
        name: 'Marca'
    },
];