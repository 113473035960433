import { fetchApi } from "../../api/fetch";
import { types } from "../../types/types";

export const startGettingStates = () => {
    return async (dispatch) => {
        try {
            const { data: { data, status } } = await fetchApi({ url: '/states', withToken: true });

            if(status) {
                dispatch(statesLoaded({ states: data }));
            } else {
                dispatch(statesLoaded({ states: [] }));
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const statesLoaded = ({ states }) => ({
    type: types.statesLoaded,
    payload: states
});