import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'quill-emoji';

import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
    'formats/emoji': EmojiBlot,
    'modules/emoji-shortname': ShortNameEmoji,
    'modules/emoji-toolbar': ToolbarEmoji,
    'modules/emoji-textarea': TextAreaEmoji,
}, true);

const modules = {
    toolbar: [
        [{ 'header': '3' }],
        ['italic', 'bold', 'underline'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
        ],
        ['link'],
        ['emoji'],
    ],
    'emoji-toolbar': true,
    "emoji-textarea": true,
    "emoji-shortname": true,
};

const formats = [
    'header',
    'italic', 'bold', 'underline',
    'list', 'bullet', 'indent',
    'link',
    'emoji',
    'image'
];

const RichTextInput = ({ value = '', handleChange, styles = {} }) => {
    return (
        <>
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={value}
                onChange={handleChange}
                style={styles}
            />
        </>
    );
};

RichTextInput.propTypes = {
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    styles: PropTypes.object,
};

export default RichTextInput;