import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Copy = (props) => {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Todos los derechos reservados © '}
            <Link color="inherit" href="https://fullmovilstore.com">
                Full Movil
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

export default Copy;