import { types } from "../../types/types";

export const uiStartLogin = () => ({
    type: types.uiStartLogin
});

export const uiFinishLogin = () => ({
    type: types.uiFinishLogin
});

export const uiCategoriesLoading = () => ({
    type: types.uiCategoriesLoading
});

export const uiCategoriesLoaded = () => ({
    type: types.uiCategoriesLoaded
});

export const uiProductsLoading = () => ({
    type: types.uiProductsLoading
});

export const uiProductsLoaded = () => ({
    type: types.uiProductsLoaded
});

export const uiProductLoading = () => ({
    type: types.uiProductLoading
});

export const uiProductLoaded = () => ({
    type: types.uiProductLoaded
});

export const uiViewLoading = () => ({
    type: types.uiViewLoading
});

export const uiViewLoaded = () => ({
    type: types.uiViewLoaded
});
