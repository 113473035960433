import { useState } from "react";

export const UseStocks = () => {

    const [stocks, setStocks] = useState([]);

    const handleSetStocks = ({ target }, id) => {        
        if (target.type === 'checkbox') {

            const checkBox = document.getElementById(`check-${id}`);
            const stockInput = checkBox.nextSibling.querySelector('input');
            const priceInput = checkBox.nextSibling.nextSibling.querySelector('input');
            const discountInput = checkBox.nextSibling.nextSibling.nextSibling.querySelector('input');

            stockInput.value = '';
            priceInput.value = '';
            discountInput.value = '';

            if (target.checked) {
                setStocks([
                    ...stocks,
                    { state_id: id, stock: '', price: '', discount_price: '' }
                ]);

                stockInput.focus();
                stockInput.setAttribute('required', 'required');
                priceInput.setAttribute('required', 'required');
            } else {
                setStocks(stocks.filter(item => item.state_id !== id));
                stockInput.removeAttribute('required');
                priceInput.removeAttribute('required');
            }
        } else {
            setStocks(stocks.map(item => item.state_id === id ? { ...item, [target.name]: target.value } : item));
        }
    };

    const resetStocks = () => setStocks([]);

    return [ stocks, setStocks, handleSetStocks, resetStocks ];
};