import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import { useForm } from '../../hooks/UseForm';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch, useSelector } from 'react-redux';
import { startAddView } from '../../actions/products/products';
import { errorAlert, warningAlert } from '../../helpers/alerts';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Typography } from '@mui/material';

import './modals.css';

const ViewsModal = ({ open, setOpen }) => {

    const dispatch = useDispatch();

    const { activeProduct } = useSelector(state => state.products);

    const [values, handleInputChange, /**/, reset] = useForm({ color_id: '' });
    const { color_id } = values;

    const [images, setImages] = useState(null);

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (Array.from(images).length > 10) {
            errorAlert({ text: 'Selecciona un máximo de 10 imagenes' });
            return;
        }

        const data = {
            color_id,
            product_id: activeProduct?.id
        };

        warningAlert({
            text: '¿Quieres guardar esta vista 360?',
            confirmButtonText: 'Guardar',
            onConfirm: () => {
                dispatch(startAddView({ data, images }));
                handleClose();
            }
        });
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Box
                className='modal_form'
                component='form'
                sx={{ backgroundColor: 'var(--mainWhite)', borderRadius: '8px' }}
                onSubmit={handleSubmit}
            >
                <Typography variant='h4' fontSize='1.2rem' color='primary'>
                    Agregar vista 360
                </Typography>

                <FormControl sx={{ margin: '16px 0 8px 0' }}>
                    <InputLabel>Color</InputLabel>
                    <Select
                        fullWidth
                        margin='dense'
                        label="Color"
                        name='color_id'
                        value={color_id}
                        onChange={handleInputChange}
                        required
                        sx={{ background: '#fff' }}
                    >
                        {
                            activeProduct?.colors?.map(color => (
                                <MenuItem value={color.id} key={color.id}>
                                    {color.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <Box
                    display='flex'
                    flexWrap='wrap'
                    alignItems='center'
                    gap='0 .5rem'
                    marginTop={2}
                >
                    <input
                        type='file'
                        accept='image/webp'
                        required
                        multiple
                        onChange={(e) => setImages(e.target.files)}
                    />
                    <InfoIcon
                        sx={{ fontSize: '1rem', cursor: 'pointer', color: 'var(--mainOrange)' }}
                        data-tooltip-id='image-info'
                        data-tooltip-content='Max. 10 imagenes / Webp.'
                        data-tooltip-place='top'
                    />
                    <Tooltip id='image-info' />
                </Box>

                <Button
                    variant="contained"
                    fullWidth
                    type='submit'
                    sx={{ margin: '2rem 0 8px 0' }}
                >
                    Guardar
                </Button>
            </Box>
        </Modal>
    )
};

ViewsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default ViewsModal;