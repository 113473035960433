import { types } from "../types/types";

const initState = {
    userData: null,
    loaded: false,
    checkingAuth: false,
    error: null
};

export const authReducer = (state = initState, action) => {
    switch (action.type) {
        case types.authLoggedIn:
            return {
                ...state,
                userData: action.payload,
                loaded: true,
                checkingAuth: false
            };

        case types.authLoggedOut:
            return {
                ...state,
                userData: null,
                loaded: true,
                checkingAuth: false
            }

        case types.authLogErrors:
            return {
                ...state,
                error: action.payload
            };
    
        default:
            return state;
    }
};