import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box, CssBaseline, Toolbar } from '@mui/material';
import Header from '../components/header/Header';
import Drawer from '../components/drawer/Drawer';
import Products from '../pages/products/Products';
import ProductForm from '../pages/productForm/ProductForm';
import Product from '../pages/product/Product';

const DashBoardRoutes = () => {

    const [drawerOpened, setDrawerOpened] = useState(false);

    return (
        <Box sx={{ display: 'flex', height: '100vh', background: 'var(--mainWhite)' }}>
            <CssBaseline />

            {/* HEADER */}
            <Header
                drawerOpened={drawerOpened}
                setDrawerOpened={setDrawerOpened}
            />

            {/* SIDEBAR */}
            <Drawer
                drawerOpened={drawerOpened}
                setDrawerOpened={setDrawerOpened}
            />

            {/* MAIN */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto'
                }}
            >
                <Toolbar />

                <Box
                    paddingTop={2}
                    width='100%'
                    display='flex'
                    gap='1rem 0'
                    flexDirection='column'
                    alignItems='center'
                >
                    <Routes>
                        <Route
                            path='productos/:category'
                            element={<Products />}
                        />
                        <Route
                            path='productos/:category/crear'
                            element={<ProductForm />}
                        />
                        <Route
                            path='productos/:category/:product'
                            element={<Product />}
                        />
                        <Route
                            path='productos/:category/:product/editar'
                            element={<ProductForm />}
                        />
                    </Routes>
                </Box>
            </Box>
        </Box>
    );
};

export default DashBoardRoutes;