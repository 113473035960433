import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../../hooks/UseForm';
import { UseStocks } from '../../hooks/UseStocks';
import { errorAlert, warningAlert } from '../../helpers/alerts';
import { useDispatch, useSelector } from 'react-redux';
import { startGettingStates } from '../../actions/states/states';
import { startAddVersion, startUpdateVersion } from '../../actions/products/products';
import { Box, Button, Checkbox, FormControlLabel, Modal, TextField, Typography } from '@mui/material';

import './modals.css';

const VersionsModal = ({ openData, setOpen }) => {

    const dispatch = useDispatch();

    const { states } = useSelector(state => state.states);
    const { activeProduct } = useSelector(state => state.products);

    const [stocks, setStocks, handleSetStocks, resetStocks] = UseStocks();
    const [values, handleInputChange, setActiveData, reset] = useForm({ name: '' });
    const { name } = values;

    const handleClose = () => {
        setOpen({ open: false, version: null });
        reset();
        resetStocks();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if(stocks.length === 0) {
            errorAlert({ text: 'Selecciona un estado' });
            return;
        }

        const data = { name, product_id: activeProduct?.id };     
        
        warningAlert({
            text: '¿Quieres registrar esta versión?',
            confirmButtonText: 'Registrar',
            onConfirm: () => {
                if(openData.version) {
                    dispatch(startUpdateVersion({ name, stocks, version_id: openData.version.id }))
                } else {
                    dispatch(startAddVersion({ data, stocks }));
                }

                handleClose();
            }
        });
    };

    useEffect(() => {
        if (openData.open && states.length === 0) dispatch(startGettingStates());

        if(openData.version) {
            setActiveData({ name: openData.version?.name });
            setStocks(openData.version?.stocks?.map(item => ({ 
                state_id: item.id, 
                stock: item.stock,
                price: Math.trunc(item.price),
                discount_price: item.discount_price ? Math.trunc(item.discount_price) : ''
            })));
        }
    }, [dispatch, openData, states]);

    return (
        <Modal
            open={openData.open}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Box
                className='modal_form'
                component='form'
                sx={{ backgroundColor: 'var(--mainWhite)', borderRadius: '8px' }}
                onSubmit={handleSubmit}
            >
                <Typography variant='h4' fontSize='1.2rem' color='primary'>
                    Registrar versión
                </Typography>

                <TextField
                    margin="normal"
                    label='Nombre'
                    required
                    fullWidth
                    type='text'
                    name="name"
                    value={name}
                    onChange={handleInputChange}
                    placeholder='ej. 128GB'
                    autoComplete="off"
                    autoFocus
                    sx={{ background: '#fff' }}
                />

                {
                    states?.map(state => (
                        <Box display='flex' flexDirection='column' justifyContent='space-between' key={state.id}>
                            <FormControlLabel
                                id={`check-${state.id}`}
                                label={state.name}
                                control={
                                    <Checkbox checked={stocks.some(item => item.state_id === state.id)} />
                                }
                                onChange={(e) => handleSetStocks(e, state.id)}
                            />
                            <TextField
                                margin="normal"
                                label='Stock'
                                type='number'
                                name="stock"
                                value={stocks.find(item => item.state_id === state.id)?.stock ?? ''}
                                autoComplete="off"
                                sx={{ background: '#fff' }}
                                onChange={(e) => handleSetStocks(e, state.id)}
                            />
                            <TextField
                                margin="normal"
                                label='Precio'
                                type='number'
                                name="price"
                                value={stocks.find(item => item.state_id === state.id)?.price ?? ''}
                                autoComplete="off"
                                sx={{ background: '#fff' }}
                                onChange={(e) => handleSetStocks(e, state.id)}
                            />
                            <TextField
                                margin="normal"
                                label='Precio de descuento'
                                type='number'
                                name="discount_price"
                                value={stocks.find(item => item.state_id === state.id)?.discount_price ?? ''}
                                autoComplete="off"
                                sx={{ background: '#fff' }}
                                onChange={(e) => handleSetStocks(e, state.id)}
                            />
                        </Box>
                    ))
                }

                <Button
                    variant="contained"
                    fullWidth
                    type='submit'
                    sx={{ margin: '2rem 0 8px 0' }}
                >
                    {
                        openData.version ? 'Guardar cambios' : 'Crear'
                    }
                </Button>
            </Box>
        </Modal>
    );
};

VersionsModal.propTypes = {
    openData: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default VersionsModal;