import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Interweave } from 'interweave';
import { useNavigate } from 'react-router-dom';

const MainInfo = ({ category, brand, price, discount_price, colors, main_features, features }) => {

    const navigate = useNavigate();

    return (
        <Paper variant='outlined' sx={{ width: '95%' }}>
            <Box padding={2}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h4' fontSize='1.2rem' color='primary'>
                        Información general
                    </Typography>
                    <Button
                        variant='contained'
                        endIcon={<EditIcon />}
                        onClick={() => navigate('editar')}
                    >
                        Editar
                    </Button>
                </Box>

                <Box marginTop={2}>
                    <p>
                        <strong>Categoria:</strong> {category?.name}
                    </p>
                    <p>
                        <strong>Marca:</strong> {brand?.name}
                    </p>
                    <p>
                        <strong>Precio: </strong>
                        {
                            parseInt(price).toLocaleString('es-CO', {
                                style: 'currency',
                                currency: 'COP',
                                minimumFractionDigits: 0
                            })
                        }
                    </p>
                    <p>
                        <strong>Precio de descuento: </strong>
                        {
                            parseInt(discount_price || 0).toLocaleString('es-CO', {
                                style: 'currency',
                                currency: 'COP',
                                minimumFractionDigits: 0
                            })
                        }
                    </p>

                    <Box display='flex' alignItems='center' gap='0 0.5rem'>
                        <strong>Colores:</strong>
                        {
                            colors?.map(color => (
                                <div
                                    key={color?.id}
                                    style={{
                                        background: color?.code,
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        border: '1px solid'
                                    }}
                                >

                                </div>
                            ))
                        }
                    </Box>
                </Box>

                <Typography marginTop={2} variant='h4' fontSize='1.2rem' color='primary'>
                    Características destacadas
                </Typography>

                <Box marginTop={2}>
                    <Interweave content={main_features} />
                </Box>

                <Typography marginTop={2} variant='h4' fontSize='1.2rem' color='primary'>
                    Características generales
                </Typography>

                <Box marginTop={2}>
                    <Interweave content={features} />
                </Box>
            </Box>
        </Paper>
    );
};

MainInfo.propTypes = {
    category: PropTypes.object,
    brand: PropTypes.object,
    price: PropTypes.string,
    colors: PropTypes.array,
    main_features: PropTypes.string,
    features: PropTypes.string
};

export default MainInfo;