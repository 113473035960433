export const types = {

    // ? UI
    uiStartLogin: '[ui] start login',
    uiFinishLogin: '[ui] finish login',
    uiCategoriesLoading: '[ui] categories loading',
    uiCategoriesLoaded: '[ui] categories loaded',
    uiProductsLoading: '[ui] products loading',
    uiProductsLoaded: '[ui] products loaded',
    uiProductLoading: '[ui] product loading',
    uiProductLoaded: '[ui] product loaded',
    uiViewLoading: '[ui] view loading',
    uiViewLoaded: '[ui] view loaded',

    // ? Auth
    authLoggedIn: '[auth] logged in',
    authLoggedOut: '[auth] logged out',
    authLogErrors: '[auth] log errors',

    // ? States
    statesLoaded: '[states] loaded', 

    // ? Categories
    categoriesLoaded: '[categories] loaded',
    categoriesSetActive: '[categories] set active',

    // ? Brands
    brandsLoaded: '[brands] loaded',
    brandsSetActive: '[brands] set active',

    // ? Colors
    colorsLoaded: '[colors] loaded',

    // ? Products
    productsLoaded: '[products] loaded',
    productsSetActive: '[products] set active',
    productUpdated: '[products] updated',
    productAddVersion: '[products] add version',
    productUpdateVersion: '[products] update version',
    productAddImage: '[products] add image',
    productUpdateImage: '[products] update image',
    productDeleteImage: '[products] delete image',
    productViewLoaded: '[products] view loaded'
};