import React, { useEffect, useState } from 'react';
import { productsColumns } from '../../helpers/columns';
import Title from '../../components/title/Title';
import TableData from '../../components/table/TableData';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startGettingProducts } from '../../actions/products/products';
import Loader from '../../components/loader/Loader';

const Products = () => {

    const dispatch = useDispatch();
    const { category } = useParams();

    const { products, total } = useSelector(state => state.products);
    const { categories } = useSelector(state => state.categories);
    const { loadingProducts } = useSelector(state => state.ui);

    const [page, setPage] = useState(0);

    useEffect(() => {
        dispatch(startGettingProducts({ page: page + 1, category }));
    }, [dispatch, page, category]);

    return (
        <>
            <Title 
                text={categories.find(e => e.slug === category)?.name ?? '...'} 
            />

            {
                loadingProducts
                    ? <Loader />
                    :
                    <TableData
                        total={total}
                        columns={productsColumns}
                        data={products}
                        page={page}
                        setPage={setPage}
                    />
            }

            {/* <CreateModal /> */}
        </>
    );
};

export default Products;