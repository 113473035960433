import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoutes = ({ children }) => {

    const { userData } = useSelector(state => state.auth);

    return userData
        ? children
        : <Navigate to='/' />
};

PrivateRoutes.propTypes = {
    children: PropTypes.any.isRequired
};

export default PrivateRoutes;