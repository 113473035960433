import { types } from "../types/types";

const initState = {
    states: []
};

export const statesReducer = (state = initState, action) => {
    switch (action.type) {
        case types.statesLoaded:
            return {
                ...state,
                states: action.payload
            };
    
        default:
            return state;
    }
};