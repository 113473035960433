export const styles = {
    palette: {
        primary: {
            main: '#F3712B',
            contrastText: '#FFFFFF'
        },
        grey: {
            main: '#353B3C',
            contrastText: '#FFFFFF'
        }
    }
};