import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar } from '../../helpers/muiStyles';
import { Avatar } from '@mui/material';

const Header = ({ drawerOpened, setDrawerOpened }) => {
    
    const toggleDrawer = () => {
        setDrawerOpened(!drawerOpened);
    };

    return (
        <AppBar position="absolute" open={drawerOpened} color='grey' sx={{ zIndex: 2 }}>
            <Toolbar
                sx={{
                    pr: '24px'
                }}
            >
                <IconButton
                    edge="start"
                    color="primary"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(drawerOpened && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    Dashboard
                </Typography>
                <Avatar 
                    src='/assets/images/logo.png' 
                    sx={{ width: 'fit-content', height: 30, borderRadius: 0 }} 
                />
            </Toolbar>
        </AppBar>
    );
};

export default Header;