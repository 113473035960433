import { fetchApi } from "../../api/fetch";
import { types } from "../../types/types";

export const startGettingBrands = () => {
    return async (dispatch) => {
        try {
            const { data: { data, status } } = await fetchApi({ url: '/brands' });

            if(status) {
                dispatch(brandsLoaded({ brands: data }));
            } else {
                dispatch(brandsLoaded({ brands: [] }));
            }
        } catch (error) {
            console.log(error);
        }
    };
};

const brandsLoaded = ({ brands }) => ({
    type: types.brandsLoaded,
    payload: brands
});