import { types } from "../types/types";

const initState = {
    brands: [],
    activeBrand: null
};

export const brandsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.brandsLoaded:
            return {
                ...state,
                brands: action.payload
            };

        case types.brandsSetActive:
            return {
                ...state,
                activeBrand: action.payload
            };
    
        default:
            return state;
    }
};