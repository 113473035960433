import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { warningAlert } from '../../helpers/alerts';
import { startLogOut } from '../../actions/auth/auth';
import { ChevronLeftRounded } from '@mui/icons-material';
import { DrawerComponent } from '../../helpers/muiStyles';
import { Divider, IconButton, List, Toolbar } from '@mui/material';
import { mainListItems, secondaryListItems } from '../listItems/ListItems';
import { startGettingCategories } from '../../actions/categories/categories';
import { useNavigate } from 'react-router-dom';

const Drawer = ({ drawerOpened, setDrawerOpened }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { categories } = useSelector(state => state.categories);

    const handleLogOut = () => {
        warningAlert({
            text: '¿Quieres salir?',
            confirmButtonText: 'Salir',
            onConfirm: () => dispatch(startLogOut())
        });
    };

    useEffect(() => {
        dispatch(startGettingCategories());
    }, [dispatch]);

    return (
        <DrawerComponent sx={{ zIndex: 1 }} variant="permanent" open={drawerOpened}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={() => setDrawerOpened(false)}>
                    <ChevronLeftRounded />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                {mainListItems({ categories, navigate })}
                <Divider sx={{ my: 1 }} />
                {secondaryListItems({ handleLogOut })}
            </List>
        </DrawerComponent>
    );
};

export default Drawer;