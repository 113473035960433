import { axiosInstance } from "./global";

export const fetchApi = async ({ 
    method = 'get', 
    url,
    data = null, 
    headers = {},
    withToken = false
}) => {
    try {
        const options = { 
            method, 
            url,
            headers: {
                ...axiosInstance.defaults.headers.common,
                ...headers
            }
        };

        if(data && method !== 'get') options.data = data;

        if(withToken) {
            const token = localStorage.getItem('access-token') || '';

            options.headers = { 
                ...options.headers,
                'Authorization': `Bearer ${token}`
            };
        }

        return await axiosInstance(options);
    } catch(error) {
        console.log(error);
        return error.response;
    }
};