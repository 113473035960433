import { useState } from "react";

export const UseColors = () => {

    const [selectedColors, setSelectedColors] = useState([]);

    const handleSelectColors = ({ target }, id) => {
        if(target.checked) {
            setSelectedColors([...selectedColors, id]);
        } else {
            setSelectedColors(selectedColors.filter(item => item !== id));
        }
    };

    return [ selectedColors, setSelectedColors, handleSelectColors ];
};