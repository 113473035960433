import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useForm } from '../../hooks/UseForm';
import InfoIcon from '@mui/icons-material/Info';
import Title from '../../components/title/Title';
import { createSlug } from '../../helpers/utils';
import { UseColors } from '../../hooks/UseColors';
import Loader from '../../components/loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import NotFound from '../../components/not-found/NotFound';
import { errorAlert, warningAlert } from '../../helpers/alerts';
import { startGettingBrands } from '../../actions/brands/brands';
import { startGettingColors } from '../../actions/colors/colors';
import { featuresTemplate } from '../../helpers/featuresTemplate';
import RichTextInput from '../../components/richInput/RichTextInput';
import { startAddProduct, startGetProduct, startUpdateProduct } from '../../actions/products/products';
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';

const initValues = {
    name: '',
    price: '',
    discount_price: '',
    category_id: '',
    brand_id: '',
};

const ProductForm = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { category, product } = useParams();

    const { categories } = useSelector(state => state.categories);
    const { activeProduct } = useSelector(state => state.products);
    const { brands } = useSelector(state => state.brands);
    const { colors } = useSelector(state => state.colors);
    const { loadingProduct } = useSelector(state => state.ui);

    const [slug, setSlug] = useState('');
    const [main_features, setMainFeatures] = useState('');
    const [features, setFeatures] = useState(featuresTemplate);
    const [image, setImage] = useState(null);

    const [selectedColors, setSelectedColors, handleSelectColors] = UseColors();
    const [values, handleInputChange, setActiveData, /**/] = useForm(initValues);
    const {
        name,
        price,
        discount_price,
        category_id,
        brand_id
    } = values;

    const getBrands = useCallback(() => {
        dispatch(startGettingBrands());
    }, [dispatch]);

    const getColors = useCallback(() => {
        dispatch(startGettingColors());
    }, [dispatch]);

    const getProduct = useCallback(() => {
        if (product && !activeProduct) dispatch(startGetProduct({ product }));
    }, [dispatch, product, activeProduct]);

    const setActiveProductData = useCallback(() => {
        if (product && activeProduct) {
            setMainFeatures(activeProduct?.main_features);
            setFeatures(activeProduct?.features);
            setSlug(activeProduct?.slug);
            setSelectedColors(activeProduct?.colors?.map(color => color.id))
            setActiveData({
                name: activeProduct?.name,
                price: Math.trunc(activeProduct?.price).toString(),
                discount_price: activeProduct?.discount_price ? Math.trunc(activeProduct?.discount_price).toString() : '',
                category_id: activeProduct?.category_id,
                brand_id: activeProduct?.brand_id,
            });
        }
    }, [product, activeProduct]);

    const handleSlug = ({ target }) => {
        handleInputChange({ target });
        setSlug(createSlug({ text: target.value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (selectedColors.length === 0) {
            errorAlert({ text: 'Por favor selecciona un color' });
            return;
        }

        if (main_features.replace(/<[^>]*>/g, '') === '' || features.replace(/<[^>]*>/g, '') === '') {
            errorAlert({ text: 'Por favor escribe las características' });
            return;
        }

        const { discount_price, ...rest } = values;

        const data = {
            ...rest,
            slug,
            main_features,
            features
        };

        if (discount_price !== '' || parseInt(discount_price) > 0) data.discount_price = discount_price;

        warningAlert({
            text: `¿Quieres ${product ? 'editar' : 'registrar'} este producto?`,
            confirmButtonText: 'Guardar',
            onConfirm: () => {
                if (product && activeProduct) {
                    dispatch(startUpdateProduct({ data, colors: selectedColors, category, navigate, product }));
                } else {
                    dispatch(startAddProduct({ data, colors: selectedColors, image, category, navigate }));
                }
            }
        });
    };

    useEffect(() => {
        getBrands();
        getColors();
        getProduct();
        setActiveProductData();
    }, [getBrands, getColors, getProduct, setActiveProductData]);

    if (loadingProduct) {
        return <Loader />;
    }

    if (product && !activeProduct && !loadingProduct) {
        return <NotFound />
    }

    return (
        <>
            <Title
                text={product ? 'Editar producto' : 'Registrar producto'}
                btn={false}
            />

            <Paper variant='outlined' sx={{ width: '95%' }}>
                <Box
                    component='form'
                    padding={2}
                    onSubmit={handleSubmit}
                >
                    <Box className='form_main-data'>
                        <TextField
                            margin="normal"
                            label='Nombre'
                            required
                            fullWidth
                            type='text'
                            name="name"
                            value={name}
                            onChange={handleSlug}
                            autoComplete="off"
                            autoFocus
                            sx={{ background: '#fff' }}
                        />
                        <TextField
                            margin="normal"
                            label='Slug'
                            required
                            fullWidth
                            type='text'
                            value={slug}
                            disabled
                            autoComplete="off"
                            sx={{ background: '#fff' }}
                        />
                        <TextField
                            margin="normal"
                            label='Precio'
                            required
                            fullWidth
                            type='number'
                            name="price"
                            value={price}
                            onChange={handleInputChange}
                            autoComplete="off"
                            sx={{ background: '#fff' }}
                        />
                        <TextField
                            margin="normal"
                            label='Precio de descuento'
                            fullWidth
                            type='number'
                            name="discount_price"
                            value={discount_price}
                            onChange={handleInputChange}
                            autoComplete="off"
                            sx={{ background: '#fff' }}
                        />

                        <FormControl sx={{ margin: '16px 0 8px 0' }}>
                            <InputLabel>Categoria</InputLabel>
                            <Select
                                fullWidth
                                label="Categoria"
                                name='category_id'
                                value={category_id}
                                onChange={handleInputChange}
                                sx={{ background: '#fff' }}
                                required
                            >
                                {
                                    categories?.map(category => (
                                        <MenuItem value={category.id} key={category.id}>
                                            {category.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <FormControl sx={{ margin: '16px 0 8px 0' }}>
                            <InputLabel>Marca</InputLabel>
                            <Select
                                fullWidth
                                margin='dense'
                                label="Marca"
                                name='brand_id'
                                value={brand_id}
                                onChange={handleInputChange}
                                required
                                sx={{ background: '#fff' }}
                            >
                                {
                                    brands?.map(brand => (
                                        <MenuItem value={brand.id} key={brand.id}>
                                            {brand.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>

                    <Box marginTop={3}>
                        <Typography variant='h4' fontSize='1.2rem' color='primary'>
                            Colores disponibles
                        </Typography>

                        {
                            colors?.map(color => (
                                <FormControlLabel
                                    key={color.id}
                                    label={color.name}
                                    control={
                                        <Checkbox checked={selectedColors.includes(color.id)} />
                                    }
                                    onChange={(e) => handleSelectColors(e, color.id)}
                                />
                            ))
                        }
                    </Box>

                    <Box marginTop={3}>
                        <Typography variant='h4' fontSize='1.2rem' color='primary'>
                            Características *destacadas*
                        </Typography>

                        <RichTextInput
                            value={main_features}
                            handleChange={setMainFeatures}
                            styles={{ marginTop: '1rem' }}
                        />
                    </Box>

                    <Box marginTop={3}>
                        <Typography variant='h4' fontSize='1.2rem' color='primary'>
                            Características generales
                        </Typography>

                        <RichTextInput
                            value={features}
                            handleChange={setFeatures}
                            styles={{ marginTop: '1rem' }}
                        />
                    </Box>

                    {
                        !product &&
                        <Box marginTop={3}>
                            <Typography variant='h4' fontSize='1.2rem' color='primary'>
                                Imagen principal
                            </Typography>

                            <Box
                                display='flex'
                                alignItems='center'
                                gap='0 .5rem'
                                sx={{ marginTop: '1rem' }}
                            >
                                <input
                                    required
                                    type='file'
                                    name='file'
                                    accept='image/webp'
                                    onChange={(e) => setImage(e.target.files[0])}
                                />
                                <InfoIcon 
                                    sx={{ fontSize: '1rem', cursor: 'pointer', color: 'var(--mainOrange)' }}
                                    data-tooltip-id='image-info' 
                                    data-tooltip-content='Max. 2MB / Webp. / Recomendado. 800 x 800px'
                                    data-tooltip-place='right'
                                />
                                <Tooltip id='image-info' />
                            </Box>
                        </Box>
                    }

                    <Button
                        variant="contained"
                        fullWidth
                        type='submit'
                        sx={{ margin: '2rem 0 8px 0' }}
                    >
                        {product ? 'Guardar cambios' : 'Crear'}
                    </Button>
                </Box>
            </Paper>
        </>
    );
};

export default ProductForm;