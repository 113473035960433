import React from 'react';
import PropTypes from 'prop-types';
import { baseApiUrl } from '../../api/global';
import { useNavigate } from 'react-router-dom';
import { Avatar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';

import './tableData.css';

const TableData = ({ columns = [], data = [], pagination = true, total, page = 1, setPage }) => {

    const navigate = useNavigate();

    const renderCell = (item, column) => {

        if (column.type === 'image') {
            const src = item[column.id].length > 0 ? item[column.id][0][column.sub] : null;
            return src 
                ? <Avatar src={`${baseApiUrl}/storage/${src}`} />
                : <Avatar>NA</Avatar>;
        }

        let itemToRender;

        if (column.sub) {
            itemToRender = item[column.id][column.sub];
        } else {
            itemToRender = item[column.id];
        }

        if (column.type === 'number') {
            const number = itemToRender ?? 0;
            return parseInt(number).toLocaleString('es-CO', {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 0
            });
        }

        return itemToRender;
    };
    
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <>
            <TableContainer
                component={Paper}
                variant='outlined'
                sx={{ width: '95%', maxHeight: '450px' }}
            >
                <Table>
                    <TableHead sx={{ background: 'var(--secondGrey)' }}>
                        <TableRow>
                            {
                                columns.map(column => (
                                    <TableCell key={column.id} sx={{ color: 'var(--mainOrange)' }}>
                                        {column.name}
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((item, index) => (
                                <TableRow 
                                    key={index} 
                                    onClick={() => navigate(`${item.slug}`)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    {
                                        columns.map(column => (
                                            <TableCell key={column.id}>
                                                {
                                                    renderCell(item, column)
                                                }
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                pagination &&
                <TablePagination
                    component='div'
                    count={total}
                    onPageChange={handlePageChange}
                    page={page}
                    rowsPerPage={10}
                    rowsPerPageOptions={[10]}
                />
            }
        </>
    );
};

TableData.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    pagination: PropTypes.bool,
    total: PropTypes.number.isRequired,
    page: PropTypes.number,
    setPage: PropTypes.func.isRequired
};

export default TableData;