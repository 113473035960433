import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactImageTurntable } from "react-image-turntable";
import { Box, Button, Modal, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { startDeleteView, startGettingView } from '../../actions/products/products';
import { baseApiUrl } from '../../api/global';
import Loader from '../loader/Loader';
import { warningAlert } from '../../helpers/alerts';

const Viewer360 = ({ openData, setOpen }) => {

    const dispatch = useDispatch();

    const { loadingView } = useSelector(state => state.ui);
    const { view360, activeProduct } = useSelector(state => state.products);

    const handleDelete = () => {
        warningAlert({
            text: '¿Quieres eliminar esta vista?',
            confirmButtonText: 'Eliminar',
            onConfirm: () => {
                dispatch(startDeleteView({ product_id: activeProduct?.id, color_id: openData.color }));
                handleClose();
            }
        })
    };

    const handleClose = () => {
        setOpen({ open: false, color: null });
    };

    useEffect(() => {
        if (openData.open) dispatch(startGettingView({ product_id: activeProduct?.id, color_id: openData.color }));
    }, [openData, activeProduct, dispatch]);

    return (
        <Modal
            open={openData.open}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Box
                className='modal_form'
                sx={{ backgroundColor: 'var(--mainWhite)', borderRadius: '8px' }}
            >
                <Typography variant='h4' fontSize='1.2rem' color='primary'>
                    Vista 360
                </Typography>

                {
                    loadingView
                        ? <Loader />
                        :
                        <>
                            <Box marginTop={2} display='flex' flexDirection='column' alignItems='center'>
                                {
                                    view360.length === 0 &&
                                    <p>No hay modelos para este color</p>
                                }
                                {
                                    view360.length > 0 &&
                                    <>
                                        <ReactImageTurntable
                                            images={view360?.map(image => `${baseApiUrl}/storage/${image.url}`)}
                                            style={{ maxWidth: '250px', cursor: 'grab', marginBottom: '1rem' }}
                                            movementSensitivity={20}
                                        />
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='error'
                                            onClick={handleDelete}
                                        >
                                            Borrar modelo
                                        </Button>
                                    </>
                                }
                            </Box>
                        </>
                }
            </Box>
        </Modal>
    );
};

Viewer360.propTypes = {
    openData: PropTypes.object.isRequired,
    setOpen: PropTypes.func.isRequired
};

export default Viewer360;