import { Box, Typography } from '@mui/material';
import React from 'react';

const NotFound = () => {
    return (
        <Box 
            display='flex'
            justifyContent='center'
            alignItems='center'
        >
            <Typography 
                variant='body1'
                fontSize='1.3rem'
                textTransform='uppercase'
                sx={{ color: '#919191' }}
            >
                Este producto no existe
            </Typography>
        </Box>
    );
};

export default NotFound;