import Swal from "sweetalert2";

export const warningAlert = ({ title = '', text = '', confirmButtonText, onConfirm }) => {
    Swal.fire({
        title,
        text,
        icon: 'warning',
        confirmButtonText,
        confirmButtonColor: 'var(--mainOrange)',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: ''                       // disable icon animation
        },
        hideClass: {
            popup: '',                     // disable popup fade-out animation
        },
    }).then((result) => {
        if (result.isConfirmed) onConfirm();
    });
};

export const errorAlert = ({ text }) => {
    Swal.fire({
        text,
        icon: 'error',
        timer: 1500,
        showConfirmButton: false,
        showCancelButton: false,
        showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: ''                       // disable icon animation
        },
        hideClass: {
            popup: '',                     // disable popup fade-out animation
        },
    })
};

export const succesAlert = ({ text, showConfirmButton = false, onConfirm = null }) => {
    Swal.fire({
        text,
        icon: 'success',
        allowOutsideClick: false,
        showConfirmButton,
        confirmButtonColor: 'var(--mainOrange)',
        showCancelButton: false,
        showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: ''                       // disable icon animation
        },
        hideClass: {
            popup: '',                     // disable popup fade-out animation
        },
    }).then((result) => {
        if (result.isConfirmed && showConfirmButton) {
            onConfirm ? onConfirm() : Swal.close();
        }
    });
};

export const loadingAlert = ({ text }) => {
    Swal.fire({
        text,
        didOpen: () => Swal.showLoading()
    });
};

export const multiSelectAlert = ({ text, firstButton, secondButton, firstAction, secondAction }) => {
    Swal.fire({
        text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: firstButton,
        confirmButtonColor: 'var(--mainOrange)',
        denyButtonColor: '#c62828',
        denyButtonText: secondButton,
        cancelButtonText: 'Cancelar',
        showClass: {
            backdrop: 'swal2-noanimation', // disable backdrop animation
            popup: '',                     // disable popup animation
            icon: ''                       // disable icon animation
        },
        hideClass: {
            popup: '',                     // disable popup fade-out animation
        },
    }).then((result) => {
        if (result.isConfirmed) {
            firstAction();
        } else if (result.isDenied) {
            secondAction()
        }
    });
};