import React from 'react';
import PropTypes from 'prop-types';

import './loader.css';

const Loader = ({ styles = {}, containStyles = {}, text = null }) => {
    return (
        <div className='loader_main' style={containStyles}>
            <span className="loader" style={styles}></span>
            
            {
                text && <span className='loader_text'>{text}</span>
            }
        </div>
    );
};

Loader.propTypes = {
    styles: PropTypes.object,
    text: PropTypes.string
};

export default Loader;