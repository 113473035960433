import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Title = ({ text, btn = true }) => {

    const navigate = useNavigate();

    return (
        <Box
            sx={{ width: '95%' }}
            display='flex'
            justifyContent='space-between'
            alignItems='center'
        >
            <Typography variant='h2' fontSize='1.4rem'>
                {text}
            </Typography>

            {
                btn &&
                <Button
                    sx={{ fontSize: '0.8rem' }}
                    variant="contained"
                    endIcon={<AddIcon />}
                    onClick={() => navigate('crear')}
                >
                    Crear
                </Button>
            }
        </Box>
    );
};

Title.propTypes = {
    text: PropTypes.string.isRequired,
    btn: PropTypes.bool
};

export default Title;