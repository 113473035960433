import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PublicRoutes = ({ children }) => {

    const { userData } = useSelector(state => state.auth);

    return userData
        ? <Navigate to='/productos/smartphones' />
        : children
};

PublicRoutes.propTypes = {
    children: PropTypes.any.isRequired
};

export default PublicRoutes;