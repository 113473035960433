import { types } from "../types/types";

const initState = {
    categories: [],
    activeCategorie: null
};

export const categoriesReducer = (state = initState, action) => {
    switch (action.type) {
        case types.categoriesLoaded:
            return {
                ...state,
                categories: action.payload
            };

        case types.categoriesSetActive:
            return {
                ...state,
                activeCategorie: action.payload
            };
    
        default:
            return state;
    }
};