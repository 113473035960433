import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import InventoryIcon from '@mui/icons-material/Inventory';
import CategoryIcon from '@mui/icons-material/Category';
import AppleIcon from '@mui/icons-material/Apple';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LogoutIcon from '@mui/icons-material/Logout';
import Loader from '../loader/Loader';

export const mainListItems = ({ categories, navigate }) => {
    return (
        <>
            <ListSubheader component="div" inset>
                Productos
            </ListSubheader>

            {
                categories?.length > 0
                    ?
                    categories.map(category => (
                        <ListItemButton 
                            key={category.id}
                            onClick={() => navigate(`/productos/${category.slug}`)}
                        >
                            <ListItemIcon>
                                <InventoryIcon color='primary' />
                            </ListItemIcon>
                            <ListItemText primary={category.name} />
                        </ListItemButton>
                    ))
                    :
                    <ListItemButton>
                        <Loader styles={{ width: '30px', height: '30px', opacity: .5 }} />
                    </ListItemButton>
            }
        </>
    );
};

export const secondaryListItems = ({ handleLogOut }) => {
    return (
        <>
            <ListSubheader component="div" inset>
                Configuración
            </ListSubheader>
            <ListItemButton onClick={handleLogOut}>
                <ListItemIcon>
                    <LogoutIcon color='error' />
                </ListItemIcon>
                <ListItemText primary="Cerrar sesión" />
            </ListItemButton>
        </>
    )
};