export const createSlug = ({ text }) => {
    return text
        .toLowerCase() // Convertir el texto a minúsculas
        .normalize("NFD") // Normalizar caracteres Unicode a sus equivalentes básicos
        .replace(/[\u0300-\u036f]/g, "") // Eliminar diacríticos
        .replace(/[^\w\s-]/g, "") // Eliminar caracteres no alfanuméricos excepto guiones y espacios
        .replace(/\s+/g, "-") // Reemplazar espacios por guiones
        .replace(/--+/g, "-") // Reemplazar múltiples guiones por uno solo
        .replace(/^-+/, "") // Eliminar guiones al principio del slug
        .replace(/-+$/, ""); // Eliminar guiones al final del slug
};