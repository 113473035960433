import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Paper, Typography } from '@mui/material';

const VersionsInfo = ({ versions, openModal }) => {
    return (
        <Paper variant='outlined' sx={{ width: '95%' }}>
            <Box padding={2}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h4' fontSize='1.2rem' color='primary'>
                        Versiones
                    </Typography>
                    <Button
                        variant='contained'
                        endIcon={<AddIcon />}
                        onClick={() => openModal({ open: true, version: null })}
                    >
                        Crear
                    </Button>
                </Box>

                <Box marginTop={2}>
                    {
                        versions?.length > 0
                            ?
                            versions?.map(version => (
                                <Box marginTop={1} key={version.id}>
                                    <strong>{version.name}</strong>
                                    <ul style={{ display: 'flex', flexDirection: 'column', gap: '1rem 0' }}>
                                        {
                                            version.stocks?.map(stock => (
                                                <li key={stock.id}>
                                                    {stock.name}
                                                    <p>
                                                        <span className='version_subtitle'>Stock:</span> {stock.stock}
                                                    </p>
                                                    <p>
                                                        <span className='version_subtitle'>Precio:</span> {parseInt(stock.price).toLocaleString('es-CO', {
                                                            style: 'currency',
                                                            currency: 'COP',
                                                            minimumFractionDigits: 0
                                                        })}
                                                    </p>
                                                    <p>
                                                        <span className='version_subtitle'>Precio de descuento:</span> {stock.discount_price ? parseInt(stock.discount_price).toLocaleString('es-CO', {
                                                            style: 'currency',
                                                            currency: 'COP',
                                                            minimumFractionDigits: 0
                                                        }) : 'Sin descuento'}
                                                    </p>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <Box marginTop={1} marginBottom={2} display='flex' gap='0 1rem'>
                                        <Button
                                            variant='contained'
                                            size='small'
                                            sx={{ fontSize: '0.7rem', padding: '0.1rem' }}
                                            onClick={() => openModal({ open: true, version: version })}
                                        >
                                            Editar
                                        </Button>
                                    </Box>
                                </Box>
                            ))
                            : <p>No hay versiones registradas</p>
                    }
                </Box>
            </Box>
        </Paper>
    );
};

VersionsInfo.propTypes = {
    versions: PropTypes.array,
    openModal: PropTypes.func.isRequired
};

export default VersionsInfo;