import { types } from "../types/types";

const initState = {
    loadingLogin: false,
    loadingCategories: false,
    loadingProducts: false,
    loadingProduct: false, 
    loadingView: false
};

export const uiReducer = (state = initState, action) => {
    switch (action.type) {
        case types.uiStartLogin:
            return {
                ...state,
                loadingLogin: true
            };

        case types.uiFinishLogin:
            return {
                ...state,
                loadingLogin: false
            };

        case types.uiCategoriesLoading:
            return {
                ...state,
                loadingCategories: true
            };

        case types.uiCategoriesLoaded:
            return {
                ...state,
                loadingCategories: false
            };

        case types.uiProductsLoading:
            return {
                ...state,
                loadingProducts: true
            };

        case types.uiProductsLoaded:
            return {
                ...state,
                loadingProducts: false
            };

        case types.uiProductLoading:
            return {
                ...state,
                loadingProduct: true
            };

        case types.uiProductLoaded:
            return {
                ...state,
                loadingProduct: false
            };

        case types.uiViewLoading:
            return {
                ...state,
                loadingView: true
            };

        case types.uiViewLoaded:
            return {
                ...state,
                loadingView: false
            };

        default:
            return state;
    }
};