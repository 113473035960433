import { types } from "../types/types";

const initState = {
    colors: [],
};

export const colorsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.colorsLoaded:
            return {
                ...state,
                colors: action.payload
            };
    
        default:
            return state;
    }
};