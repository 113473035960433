import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Copy from '../../components/copy/Copy';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks/UseForm';
import { startLogin } from '../../actions/auth/auth';

import './login.css';

const Login = () => {

    const dispatch = useDispatch();

    const { error } = useSelector(state => state.auth);
    const { loadingLogin } = useSelector(state => state.ui);

    const [values, handleInputChange] = useForm({
        email: '',
        password: ''
    });

    const { email, password } = values;

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(startLogin({ email, password }));
    };

    return (
        <div className='login_main'>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        sx={{ m: 3, width: 'fit-content', height: 40, borderRadius: 0 }}
                        src='/assets/images/logo.png'
                    />
                    <Typography component="h2" variant="h5">
                        Dashboard administrativo
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>

                        {
                            error &&
                            <Typography
                                variant="body2"
                                color='error'
                                align='center'
                                fontSize={14}
                                sx={{
                                    margin: '1rem 0'
                                }}
                            >
                                {error}
                            </Typography>
                        }

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo electrónico"
                            type='email'
                            name="email"
                            value={email}
                            onChange={handleInputChange}
                            autoComplete="off"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            value={password}
                            onChange={handleInputChange}
                            label="Contraseña"
                            type="password"
                        />


                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color='primary'
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loadingLogin}
                        >
                            {!loadingLogin ? 'Ingresar' : 'Ingresando...'}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Typography variant="body2" align='center' fontSize={12}>
                                    Si olvidaste tu contraseña, comunícate con el administrador del sistema
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copy sx={{ mt: 8, mb: 4 }} />
            </Container>
        </div>
    );
};

export default Login;