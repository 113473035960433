import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Chip, Paper, Typography } from '@mui/material';

const ViewsInfo = ({ openModal, openViewer, colors }) => {
    return (
        <Paper variant='outlined' sx={{ width: '95%', marginBottom: '1rem' }}>
            <Box padding={2}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h4' fontSize='1.2rem' color='primary'>
                        Modelos 360
                    </Typography>
                    <Button
                        variant='contained'
                        endIcon={<AddIcon />}
                        onClick={openModal}
                    >
                        Crear
                    </Button>
                </Box>

                <Box marginTop={2} display='flex' gap='0 1rem' flexWrap='wrap'>
                    {
                        colors?.map(color => (
                            <Chip
                                key={color.id}
                                icon={<VisibilityIcon />} 
                                label={color.name} 
                                sx={{ cursor: 'pointer' }}
                                onClick={() => openViewer({ open: true, color: color.id })}
                            />
                        ))
                    }
                </Box>
            </Box>
        </Paper>
    );
};

ViewsInfo.propTypes = {
    openModal: PropTypes.func.isRequired,
    openViewer: PropTypes.func.isRequired,
    colors: PropTypes.array
};

export default ViewsInfo;