import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import { baseApiUrl } from '../../api/global';
import { Avatar, Box, Button, Paper, Typography } from '@mui/material';
import { errorAlert, multiSelectAlert } from '../../helpers/alerts';
import { useDispatch } from 'react-redux';
import { startDeleteImage, startUpdateImage } from '../../actions/products/products';

const Images = ({ images, openModal }) => {

    const dispatch = useDispatch();

    const handleAction = (image) => {
        multiSelectAlert({
            text: 'Elige una acción para la imagen',
            firstButton: image.is_main ? 'Quitar principal' : 'Poner principal',
            secondButton: 'Eliminar',
            firstAction: () => {
                if(image.is_main) {
                    dispatch(startUpdateImage({ image_id: image.id, is_main: 0 }));
                    return;
                }

                if(!image.is_main) {
                    if(images.some(image => image.is_main)) {
                        errorAlert({ text: 'Ya hay una imagen principal' });
                        return;
                    }

                    dispatch(startUpdateImage({ image_id: image.id, is_main: 1 }));
                } 
            },
            secondAction: () => dispatch(startDeleteImage({ image_id: image.id }))
        });
    };

    return (
        <Paper variant='outlined' sx={{ width: '95%' }}>
            <Box padding={2}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h4' fontSize='1.2rem' color='primary'>
                        Imagenes
                    </Typography>
                    <Button
                        variant='contained'
                        endIcon={<AddIcon />}
                        onClick={openModal}
                    >
                        Agregar
                    </Button>
                </Box>

                <Box marginTop={2} display='flex' gap='0 1rem' flexWrap='wrap'>
                    {
                        images?.map(image => (
                            <Avatar
                                key={image?.id}
                                className={image.is_main ? 'main_image' : ''}
                                src={`${baseApiUrl}/storage/${image?.url}`}
                                sx={{ borderRadius: 0, width: 'fit-content', height: '150px', cursor: 'pointer' }}
                                onClick={() => handleAction(image)}
                            />
                        ))
                    }
                </Box>
            </Box>
        </Paper>
    );
};

Images.propTypes = {
    images: PropTypes.array,
    openModal: PropTypes.func.isRequired
};

export default Images;